<template>
  <div class="ecommerce-application">
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              포인트 기프트 교환
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="addProductKt"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>기프티쇼 상품 추가</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click.prevent="addProductKaKao"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>카카오톡 상품 추가</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="3"
          >
            <b-form-group
              label="기프티쇼 비즈 잔여 비즈머니"
              label-for="balanceKt"
            >
              <b-form-input
                id="balanceKt"
                v-model="balanceKt"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="카카오톡 선물하기 비즈 잔여 비즈머니"
              label-for="balanceKaKao"
            >
              <b-form-input
                id="balanceKaKao"
                v-model="balanceKaKao"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isSortMode"
            cols="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="ml-1"
              @click.prevent="saveSorting()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>순서 변경 저장</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="changeSortMode(false)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>취소</span>
            </b-button>
          </b-col>
          <b-col
            v-if="isSortMode === false"
            cols="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              @click.prevent="changeSortMode(true)"
            >
              <feather-icon
                icon="ListIcon"
                class="mr-50"
              />
              <span>정렬 순서 변경</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <draggable
      v-model="products"
      :options="{disabled : !isSortMode}"
    >
      <transition-group
        class="grid-view wishlist-items"
      >
        <b-card
          v-for="item in products"
          :key="item.goodsCode"
          class="ecommerce-card"
          no-body
        >
          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div class="item-rating">
                {{ `[${item.companyName}] ${item.brandName}` }}
              </div>
              <div>
                <h6 class="item-price">
                  {{ item.pointAmount | $numberFormatter }}P
                </h6>
              </div>
            </div>
            <h6 class="item-name">
              {{ item.goodsName }}
            </h6>
            <b-card-text class="item-description">
                &nbsp;
            </b-card-text>
          </b-card-body>
          <div class="item-img text-center">
            <b-img
              fluid
              class="card-img-top"
              :src="item.goodsImgUrl"
            />
          </div>
          <!-- Action Buttons -->
          <div class="item-options text-center">
            <b-button
              variant="light"
              class="btn-wishlist remove-wishlist"
              @click.prevent="removeProduct(item)"
            >
              <feather-icon icon="XIcon" />
              <span>상품 제거</span>
            </b-button>
            <b-button
              variant="light"
              class="btn-cart btn-primary"
              @click.prevent="clickImageChange(item)"
            >
              <feather-icon icon="ImageIcon" />
              <span>이미지 변경</span>
            </b-button>
          </div>
        </b-card>
      </transition-group>
    </draggable>

    <b-form-file
      id="thumbFile"
      accept="image/*"
      style="visibility: hidden;"
      @input="changeThumbFile"
    />
    <gift-search
      :show-gift-search="showGiftSearch"
      @close="giftSearch.close"
    />
    <gift-kakao-reg
      :is-show="showGiftKaKaoReg"
      @close="giftKaKaoReg.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import {
  BImg, BCardBody, BCardText, BFormFile,
} from 'bootstrap-vue'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { clone, isEqual } from '@core/utils/utils'
import { numberFormatter, errorFormatter } from '@core/utils/filter'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GiftSearch from '@/views/apps/popup/GiftSearch.vue'
import GiftKakaoReg from '@/views/apps/popup/GiftKakaoReg.vue'

export default {
  components: {
    BImg,
    BCardBody,
    BCardText,
    BFormFile,
    draggable,

    GiftSearch,
    GiftKakaoReg,
  },

  setup() {
    onMounted(() => {
      fetchCashBalance(1)
      fetchCashBalance(2)
      fetchGiftProducts()
    })
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    let ogProducts = null
    const isSortMode = ref(false)
    const changeSortMode = isSort => {
      if (isSort) {
        ogProducts = clone(products.value)
      } else {
        fetchGiftProducts()
      }
      isSortMode.value = isSort
    }

    const itemRef = ref(null)
    const clickImageChange = item => {
      itemRef.value = item
      document.getElementById('thumbFile').click()
    }
    const changeThumbFile = file => {
      if (!file) return

      if (file.type.indexOf('image') === -1) {
        pushToast('danger', '잘못된 파일 형식입니다.')
        return
      }

      if (!itemRef.value) {
        pushToast('danger', '썸네일 변경 대상 상품정보를 찾을 수 없습니다.')
        return
      }

      const formdata = new FormData()
      formdata.append("GoodsCode", itemRef.value.goodsCode)
      formdata.append("ThumbFile", file)

      axios.post("/fa/point-gift/change-thumb-file", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          pushToast('success', '썸네일 이미지 변경 완료')
          fetchGiftProducts()
        })
        .catch(err => {
          pushToast('danger', errorFormatter(err, '데이터 조회에 실패하였습니다.'))
        })
    }

    const saveSorting = () => {
      if (ogProducts !== null && isEqual(ogProducts, products.value)) {
        pushToast('warning', '정렬 순서가 이전과 동일합니다.')
        return
      }
      axios.post('/fa/point-gift/changeSort', products.value)
        .then(() => {
          pushToast('success', '상품 정렬 순서 변경 완료')
          isSortMode.value = false
          fetchGiftProducts()
        })
        .catch(() => {
          pushToast('danger', '정렬 순서 변경에 실패하였습니다.')
        })
    }

    const balanceKt = ref(0)
    const balanceKaKao = ref(0)
    const fetchCashBalance = companyType => {
      axios.get(`/fa/point-gift/cash-balance/${companyType}`)
        .then(rs => {
          const { balance } = rs.data

          switch (companyType) {
            case 1:
              balanceKt.value = numberFormatter(balance)
              break

            case 2:
              balanceKaKao.value = numberFormatter(balance)
              break

            default:
              pushToast('danger', '잔여 비즈머니 조회에 실패하였습니다.')
              break
          }
        })
        .catch(() => {
          pushToast('danger', '잔여 비즈머니 조회에 실패하였습니다.')
        })
    }

    const products = ref(null)
    const fetchGiftProducts = () => {
      axios.get('/fa/point-gift/products')
        .then(rs => {
          products.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '등록된 상품 조회에 실패하였습니다.')
        })
    }

    const showGiftSearch = ref(false)
    const giftSearch = {
      close: rs => {
        showGiftSearch.value = false
        if (!rs) {
          return
        }
        if (products.value && products.value.length) {
          for (let i = 0; i < products.value.length; i += 1) {
            const item = products.value[i]
            if (item.goodsCode === rs.goodsCode) {
              pushToast('warning', `${rs.goodsName} 상품은 이미 등록되어 있습니다.`)
              return
            }
          }
        }

        bvModal
          .msgBoxConfirm(`${rs.goodsName} 상품을 등록 하시겠습니까?`, {
            size: 'sm',
            cancelVariant: 'outline-secondary',
            centered: true,
          })
          .then(confirm => {
            if (!confirm) return

            let sortSeq = 1
            if (products.value != null && products.value.length > 0) {
              sortSeq = Math.max(...products.value.map(o => (o.sortSeq || 0))) + 1
            }
            axios.put('/fa/point-gift/add', {
              goodsCode: rs.goodsCode,
              goodsName: rs.goodsName,
              realPrice: rs.realPrice,
              company: rs.company,
              sortSeq,
            })
              .then(() => {
                pushToast('success', '신규 상품 등록 완료')

                fetchGiftProducts()
              })
              .catch(() => {
                pushToast('danger', '상품 등록 과정에서 오류가 발생하였습니다.')
              })
          })
      },
    }

    const showGiftKaKaoReg = ref(false)
    const giftKaKaoReg = {
      close: rs => {
        showGiftKaKaoReg.value = false
        if (!rs) {
          return
        }
        if (products.value && products.value.length) {
          for (let i = 0; i < products.value.length; i += 1) {
            const item = products.value[i]
            if (item.goodsCode === rs.goodsCode) {
              pushToast('warning', `${rs.goodsName} 상품은 이미 등록되어 있습니다.`)
              return
            }
          }
        }

        bvModal
          .msgBoxConfirm(`${rs.goodsName} 상품을 등록 하시겠습니까?`, {
            size: 'sm',
            cancelVariant: 'outline-secondary',
            centered: true,
          })
          .then(confirm => {
            if (!confirm) return

            let sortSeq = 1
            if (products.value != null && products.value.length > 0) {
              sortSeq = Math.max(...products.value.map(o => (o.sortSeq || 0))) + 1
            }
            axios.put('/fa/point-gift/add', {
              goodsCode: rs.goodsCode,
              goodsNo: rs.goodsNo,
              goodsName: rs.goodsName,
              goodsTypeName: rs.goodsTypeNm,
              brandName: rs.brandName,
              realPrice: rs.realPrice,
              discountPrice: rs.discountPrice,
              goodsImgUrl: rs.goodsImgUrl,
              company: rs.company,
              sortSeq,
            })
              .then(() => {
                pushToast('success', '신규 상품 등록 완료')

                fetchGiftProducts()
              })
              .catch(() => {
                pushToast('danger', '상품 등록 과정에서 오류가 발생하였습니다.')
              })
          })
      },
    }

    const addProductKt = () => {
      showGiftSearch.value = true
    }

    const addProductKaKao = () => {
      showGiftKaKaoReg.value = true
    }

    const removeProduct = item => {
      bvModal
        .msgBoxConfirm(`${item.goodsName} 상품을 삭제 하시겠습니까?`, {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/point-gift/remove/${item.goodsCode}`)
            .then(() => {
              pushToast('success', '상품 삭제 완료')
              fetchGiftProducts()
            })
            .catch(() => {
              pushToast('success', '상품을 삭제하는 과정에서 오류가 발생하였습니다.')
            })
        })
    }

    return {
      balanceKt,
      balanceKaKao,
      products,
      giftSearch,
      addProductKt,
      addProductKaKao,
      showGiftSearch,
      removeProduct,
      isSortMode,
      changeSortMode,
      saveSorting,
      clickImageChange,
      changeThumbFile,
      showGiftKaKaoReg,
      giftKaKaoReg,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
